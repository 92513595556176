
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.Content {
    display: flex;
}

.ContentSidebar {
    width: 240px;
    background-color: #fff;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    border-top-right-radius: $radius5;
    border-bottom-right-radius: $radius5;
}

.ContentMain {
    margin-left: 240px;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.ContentMainContainer {
    padding: $spacing20 $spacing12;
    position: relative;
    z-index: 10;
}

.ContentSidebarMenuLink {
    &, &Active {
        text-decoration: none;
    }

    .ContentSidebarManuItem, &Active .ContentSidebarManuItem  {

        padding: $spacing8;
        position: relative;
        color: $colorForeground2;
        stroke: $colorForeground2;
        font-weight: 500;      

        display: flex;
        align-items: center;


        .ContentSidebarManuItemIcon {
            height: 24px;
        }
    }

    &Active .ContentSidebarManuItem {
        background-color: #FF8C210D;
        color: $colorBlack;
        stroke: $colorBlack;
        &:hover {
            // background: #000;
        }

        
        &::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 78%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: $colorAccent;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }


}

.ContentSidebarTopLogo {
    display: flex;
    align-items: center;
    color: $colorAccent;

    div:first-child {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $colorAccent;
        margin-right: 16px;
    }
}

.ContentSidebarBottom {
    position: absolute;
    bottom: 0;
    width: 100%;

    .ContentSidebarBottomLogout {
        color: #f00;
        stroke: #f00;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
}

.AlertContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 480px;
    z-index: 999999;
}
