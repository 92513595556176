
          @use "~@aurora/core/dist/index.scss" as *;
        
.VacanciesList {
    display: flex;
    align-items: center;
}

.VacanciesList .AddVacancionButton {
    width: fit-content;
    display: flex;
    align-items: center;
    // justify-content: space-between;
}

.VacationList {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.VacationListLeftSide {
    width: 75%;
}

.VacationListRightSide {
    background-color: #fff;
    width: 20%;
    padding: $spacing12 $spacing8;
    border-radius: $radius3;
    height: fit-content;
}
