
          @use "~@aurora/core/dist/index.scss" as *;
        


.authContainer {
  padding: 40px;
  position: absolute;
  width: 455px;
  height: 320px;

  background: #FFFFFF;
  border-radius: 24px;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}


.authLogo {
  color: #091323;
  margin-top: 0;
  text-align: center;
}
.section {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 20px;
}
.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
}

.buttonSection {
  display: flex;
  justify-content: space-between;
}

.buttonLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  width: 100%;
  height: 50px;
  opacity: 0.9;
  border-radius: 13px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form {
  display: grid;
  height: 100%;
  grid-gap: 24px;
  justify-content: center;
  align-items: end;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}