
          @use "~@aurora/core/dist/index.scss" as *;
        
.Autocomplete {
    width: 100%;
}
.LabelSearchBlock {
    border-width: 2px;
    border-style: solid;
    border-color: $colorContent2;
    border-radius: 8px;
}