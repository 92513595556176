
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.RightSidebarWrapper {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  visibility: hidden;
  display: flex;
  pointer-events: none;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
}

.LeftSideWrapper {
  height: 100%;
  width: 30%;
  position: relative;
}

.RightSidebarWrapper.show {
  pointer-events: all;
  visibility: visible;


  .LeftSideWrapper,
  &, .RightSidebarChildren {
    opacity: 1;
  }
}

.RightSidebar {
  transform: translateX(90%);
  width: 70%;
  height: auto;
  background: #f8f8f8;
  overflow-y: auto;
  padding: 40px;
  transition: all 0.3s ease;
  opacity: 1;
  border-radius: $radius5 0 0 $radius5;

  .RightSidebarChildren {
    opacity: 0;
    height: 100%;
  }
}

.RightSidebar .CandidatesTable tbody tr {
  cursor: default;
}

.RightSidebarWrapper.show .RightSidebar {
  transform: translateX(0%);
}

.LeftSideWrapperClose {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 12px;
  transform: translateY(-40px);
  border-radius: 50%;
  background: $colorContent2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: .8;

  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 1;
  }

  &.LeftSideWrapperCloseOpen {
    transform: translateY(12px);
  }
}
