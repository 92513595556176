
          @use "~@aurora/core/dist/index.scss" as *;
        


.ContextMenu {
  position: absolute;
  width: 100%;
  z-index: 1000;
  text-align: left;
  width: fit-content;
  min-width: 250px;
}

.ContextMenuList {
  border-radius: 12px;
  background-color: #fff;
}


.ContextMenuListItem {
  padding: 12px;
}

.ContextMenuButtonCircle {
  height: 40px;
}