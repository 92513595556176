
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.CandidateCardHeaderButton {
  display: flex;
}
.CandidateCardHeader {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.CandidateCardData {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;

  > div {
    display: flex;
    flex: 0 0 50%;
    justify-content: space-between;
    align-items: center;
  }

.CandidateCardDataIcons {
  zoom: 0.8;
}
}
.CandidateCardVacancies {
  table th:last-of-type,
  table td:last-of-type {
    padding: 0;
  }
}
.CandidateCardVacancies,
.CandidateCardHistory {
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    tbody tr {
      border-bottom: 1px solid $colorForeground4;
      color: $colorForeground;
    }

    th,
    td {
      padding: 20px 16px;
      text-align: left;
    }
    thead {
      th {
        color: $colorForeground2;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.CandidateCardDialog {
  position: relative;
  height: 85%;
}

.CandidateCardDialogMessage {
  width: 340px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: fit-content;
  margin: auto;

  .CandidateCardDialogMessageHelper {
    font-size: $fontSizeNormal;
  }
}

.CandidateCardCreateChatForm {
  .CandidateCardCreateChatFormSave {
    width: 200px;
  }
}

.CandidateCardCreateChat {
  display: flex;
  flex-direction: column;
  height: 100%;

  .CandidateCardCreateChatField {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .CandidateCardCreateChatFieldMessage {
    width: 95%;
  }

  .CandidateCardCreateChatFieldSendBtn {
    width: 5%;
  }
}

.CandidateCardChat {
  position: relative;
  height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;

  .CandidateCardChatCard {
    position: relative;
    width: 60%;
    border-radius: $radius3;
    font-size: $fontSizeNormal;

    .CandidateCardChatTime {
      font-size: $fontSizeTiny;
      right: $spacing4;
      bottom: $spacing2;
      position: absolute;
    }
  }

  .CandidateCardGroup .CandidateCardChatCard {
    border-radius: $radius1;
    margin-bottom: $spacing2;

    &:first-child {
      border-radius: $radius3 $radius3 $radius1 $radius1;
    }

    &:last-child {
      border-radius: $radius1 $radius1 $radius3 $radius3;

    }
  }

  .CandidateCardChatDate {
    display: flex;
    justify-content: center;

    .CandidateCardChatDateValue {
      text-align: center;
      font-size: 13px;
      color: #76787D;
    }

    .CandidateCardChatDateValueActive {
      padding: 6px 12px;
      background: white;
      border-radius: 100px;
      width: 87px;
      height: 28px;
      line-height: 28px;
      color: #091323;
    }
  }

  .CandidateCardChatStatus {
    position: absolute;
    bottom: -24px;
    right: 0;
    font-size: $fontSizeSmall;
    color: $colorForeground2;
  }

  .CandidateCardChatCardMyMessage {
    background-color: $colorAccent;
    color: $colorWhite;
    margin-left: auto;
  }

  .CandidateCardChatCardCandidateMessage {
    background-color: $colorWhite;
  }
}

.CandidateCardChatField {
  &,
  textarea {
    transition: all ease-in 0.2s;
    height: 45px !important;
  }
}

.CandidateCardChatFieldFocus {
  textarea,
  & {
    height: 90px !important;
  }
}
