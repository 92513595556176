
          @use "~@aurora/core/dist/index.scss" as *;
        
.CandidatesListContainer {
    position: relative;
    z-index: 10;
}
.CandidatesTable {
    align-items: center;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    &__point {
        cursor: pointer;
        &:hover {
            color: $colorForeground;
        }
    }

    th,
    td {
        padding: $spacing6;
        text-align: left;
    }

    th {
        color: #76787d;
        font-size: 13px;
        font-weight: 500;
        div {
            display: inline-block;
        }
    }

    tbody tr {
        cursor: pointer;
        transition: all ease-in 0.1s;
        position: relative;

        &:hover {
            background-color: $colorTransparentHover;
        }
        td:last-of-type {
            padding-left: 8px;
        }
    }
    &__icon {
        position: relative;

        &__align_left {
            right: 3px;
        }

        &__align_right {
            left: 3px;
        }
    }
}

.indicatorNewMessages {
    position: absolute;
    left: 4px;
    top: 8px;
}

.deleteCandidateWrapper {
    width: 600px;
    padding: 0 !important;

    .deleteCandidateContent {
        width: 100%;
    }

    .deleteCandidateButtons {
        display: flex;
    }
}
