
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.VacationTable {
    align-items: center;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    &__point {
        cursor: pointer;
        &:hover {
            color: $colorForeground;
        }
    }
    th,
    td {
        padding: $spacing6;
        text-align: left;
    }

    th {
        color: #76787d;
        font-size: 13px;
        font-weight: 500;
        div {
            display: inline-block;
        }
    }
    &__icon {
        position: relative;

        &__align_left {
            right: 3px;
        }

        &__align_right {
            left: 3px;
        }
    }

    .VacationListTable {
        cursor: pointer;
        transition: all ease-in 0.1s;
        &:hover {
            background-color: $colorTransparentHover;
        }
    }

}
