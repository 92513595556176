
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}



body {
  margin: 0;
  font-family: $fontFamilyPrimaryRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

p {
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-decoration-none {
  text-decoration: none;
}
.pagination {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}

.bg-content-2 {
  background-color: $colorContent2;
}

.link {
  text-decoration: none;
  color: $colorAccent;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-40 {
  width: 40%;
}

.w-100 {
  width: 100%;
}

.circle-btn-height {
  height: 40px;
}

.d-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

$border-positions: bottom, top, right, left;

@each $position in $border-positions {
  .border-#{$position} {
    border-#{$position}: 1px solid $colorContent2;
  }
  .border-#{$position}-none {
    border-#{$position}: none;
  }
}

.skeletons-flex-field {
  display: flex;
  justify-content: space-between;
  > div {
    &:not(:last-child) {
      margin-right: $spacing20;
    }
    width: 50%;
  }
}

.title-and-buttons-align {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 160px;
    &:not(:first-child) {
      margin-left: $spacing6;
    }
  }
}

.slice-to-2 {
  display: flex;
  > div {
    width: 50%;
    &:not(:first-child) {
      margin-left: $spacing12;
    }
  }
}

.hr-table-style,
.hr-table-style-not-fixed {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  &.hr-table-style-not-fixed {
    table-layout: auto;
  }

  th,
  td {
    padding: $spacing6;
    text-align: left;
  }

  th {
    color: #76787d;
    font-size: 13px;
    font-weight: 500;
  }

  tbody tr {
    border-top: 1px solid $colorContent2;
    td {
      text-overflow: ellipsis;
    }
  }

  tr.can-click {
    cursor: pointer;
    transition: all ease-in 0.1s;

    &:hover {
      background-color: $colorTransparentHover;
    }
  }
}

.hr-content {
  margin-top: $spacing12;
  display: flex;
  justify-content: space-between;

  .hr-content-left {
    width: 75%;
  }

  .hr-content-right {
    background-color: #fff;
    width: 20%;
    padding: $spacing12 $spacing8;
    border-radius: $radius3;
    height: fit-content;
  }
}

.ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-snackbar {
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  &.alert-snackbar-success {
    background: #e3f6da;
    color: #5bbd2c;

    svg {
      zoom: 2;
    }
  }

  &.alert-snackbar-fail {
    background: #f6cac9;
    color: #ea3838;
  }

  &.alert-snackbar-warning {
    background: #fce2c9;
    color: #ff8c21;
  }
}

.formatted-text {
  white-space: pre-wrap; /* Сохраняем пробелы и переносы строк */
  tab-size: 4; /* Задаем размер табуляции */
}

.truncate-cell,
.truncate-cell-percent {
  max-width: 100px; /* Максимальная ширина ячейки */
  white-space: nowrap; /* Запрещаем перенос текста на новую строку */
  overflow: hidden; /* Обрезаем текст, выходящий за границы ячейки */
  text-overflow: ellipsis; /* Добавляем многоточие (...) для обозначения обрезанного текста */

  &.truncate-cell-percent {
    max-width: 100%;
  }
}
