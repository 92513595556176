
          @use "~@aurora/core/dist/index.scss" as *;
        
.MassOperationBlock {
    background-color: #fff;
    width: 300px;
    padding: 24px 16px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: fit-content;
    position: absolute;
    right: 0;
    top: 100px;
    opacity: 0.8;
    transition: all 0.2s linear;


    &:hover {
        opacity: 1;

    }
}

.modal {
    width: 600px !important;
    .btnGroup {
        width: fit-content;
        display: flex;
        float: right;
    }
}

.CandidateListAfterSelected {
    max-height: 360px;
    overflow-y: scroll;
}
.CandidatesTable {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    th,
    td {
        padding: 10px;
        text-align: left;
    }

    th {
        color: #76787d;
        font-size: 13px;
        font-weight: 500;
    }

    tbody tr {
        border-top: 1px solid #e0e9ec;
        cursor: pointer;
        transition: all ease-in 0.1s;
        &:hover {
            background-color: $colorTransparentHover;
        }
    }
}
