
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.CreateOrUpdateVacancyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.CreateOrUpdateVacancyHeader .ButtonStyles {
    width: 220px;
}

.CreateOrUpdateVacancyForm {
    margin-top: 40px;
}

.CreateOrUpdateVacancyFormField {
    display: flex;
    justify-content: space-between;
    > div {
        &:not(:last-child) {
            margin-right: 32px;
        }
        width: 50%;
    }
}

.CreateOrUpdateVacancyFormFieldAttach {
    margin: 0 -12px;
}

.showSnackbar {
    position: fixed;
    right: $spacing12;
    top: $spacing12;
}