
          @use "~@aurora/core/dist/index.scss" as *;
        
.CandidatesHeader {
  display: flex;
  align-items: center;

  .CandidateCreateButtonContainer {
    position: relative;
  }


  .CandidateCreateButtonMenu {
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  .CandidateCreateButtonMenuCard {
    border-radius: 12px;
    background-color: #fff;
  }
  .HeaderRightButtons {
    display: flex;
    justify-content: space-between;
    width: 488px;
  }
  .MassEffect {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .CreateButton {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.CandidatesList {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;


}
.CandidatesListLeftSide {
  width: 75%;
  position: relative;
  z-index: 10;
}
.CandidatesListRightSide {
  background-color: #fff;
  width: 20%;
  padding: 24px 16px;
  border-radius: 12px;
  height: fit-content;
}

