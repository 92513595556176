
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;
.EditCandidateHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EditCandidateHeaderButtons {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.EditCandidateFormFio {
  display: flex;
  justify-content: space-between;
  > div {
    width: 33.33%;
    margin: 12px 16px;
  }
}
.EditCandidateFormContact {
  display: flex;
  align-items: flex-start;
  > div {
    width: 50%;
    margin: 12px 16px;
  }
}