
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;


.VacationCard {
}

.VacationCardHeader {
    justify-content: space-between;
}

.VacationCardHeaderButtons,
.VacationCardHeader,
.VacationCardInfo {
    display: flex;
}
.VacationCardHeaderButtons {
    column-gap: 8px;
    align-items: baseline;
}
.VacancyHistory {
    table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        tbody tr {
            border-bottom: 1px solid $colorForeground4;
            color: $colorForeground;
        }
        th,
        td {
            padding: $spacing4 $spacing2;
            text-align: left;
        }
        thead {
            th {
                color: $colorForeground2;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}


