
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;


.modal {
    width: 580px !important;
    overflow: visible !important;
}
