
          @use "~@aurora/core/dist/index.scss" as *;
        
.AdditionallyMenuContainer {
  position: relative;
}

.AdditionallyMenu {
  position: absolute;
  width: 100%;
  z-index: 1000;
  text-align: left;
  width: fit-content;
  min-width: 250px;
}

.AdditionallyMenuList {
  border-radius: 12px;
  background-color: #fff;
}


.AdditionallyMenuListItem {
  padding: 12px;
}

.AdditionallyMenuButtonCircle {
  height: 40px;
}