
          @use "~@aurora/core/dist/index.scss" as *;
        
.VacationCardInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;

    > div {
        flex: 0 0 33.333333%;
    }
}


