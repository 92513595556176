
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;


.modal {
  width: 831px !important;
  overflow: visible !important;
  min-height: 366px;
  button, button p {
    font-size: 15px;
  }
  .ButtonSuccess {
    width: 200px;
  }
}