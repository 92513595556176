
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.LabelMultiselectBadgeColor {
  div[class*="Tag-module_tag"] {
    background: $colorAttentionBackground;
  }
  span[class*="Text-module_text"] {
    color: $colorAttention !important;
  }
  div[class*="Tag-module_tag__cross"] {
    color: $colorAttention !important;
  }
}

.BtnSaveHidden {
  display: none !important;
}

.MainBackgroundColor {
  background-color: #f8f8f8 !important;
}

.Tooltip {
  div[class*="Tooltip-module_tooltip--placement-top"] {
    transform: translateX(-10%) translateY(calc(-100% - 12px));
    &:before {
      left: 10% !important;
    }
  }
}