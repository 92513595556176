
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;


.modal {
    width: 740px !important;
    overflow: visible !important;
    min-height: 270px;

}


.vacancies {
    max-height: 350px;
    overflow: auto;
}