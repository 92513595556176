
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;

.hidden {
    display: none;
}

.modalContent {
    width: 1112px !important;
    // overflow: visible !important;
    min-height: 269px;
    // position: relative;
    overflow: hidden;
    overflow-y: auto;
}

.modalContainer {
    overflow: hidden;
}

.modalTableLine {
    position: relative;

    td.modalTableEdit {
        position: absolute;
        left: 0;
        width: 86%;
        background: $colorBackground2;
        height: 95%;
    }

    .modalTableLineEditContainer {
        display: flex;
        justify-content: space-between;

        .modalTableLineEditContainerMainInfo {
            width: 31%;
        }

        .modalTableLineEditContainerSecondInfo {
            width: 48%;
        }
    }

    &.modalTableLineEditable {
        height: 170px;
        td {
            vertical-align: top;
        }
    }

    td.TextAlignRight {
        text-align: end;
    }
}
