
          @use "~@aurora/core/dist/index.scss" as *;
        
.CandidatesFilterBlock {
  display: flex;
  align-items: center;

  .CandidatesFilterBlockField {
    width: 33%;
  }
}
