
          @use "~@aurora/core/dist/index.scss" as *;
        
@use "~@aurora/core/dist/index.scss" as *;


.modal {
    width: 740px !important;
    overflow: visible !important;
    min-height: 269px;
}
.multiselectDiv > {
    div:first-of-type > div {
        overflow: auto;
        max-height: 131px;
    }
}
